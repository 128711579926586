var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-bar" }, [
    _c(
      "form",
      {
        staticClass: "row no-gutters",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.search.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-10" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.query,
                expression: "query",
              },
            ],
            staticClass: "search-bar__input",
            attrs: { placeholder: _vm.placeholder, type: "text" },
            domProps: { value: _vm.query },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.query = $event.target.value
              },
            },
          }),
        ]),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("button", { staticClass: "search-bar__button" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }