<template>
    <div class="search-bar">
        <form 
            class="row no-gutters" 
            @submit.prevent="search">

            <div class="col-10">
                <input
                    v-model="query"
                    :placeholder="placeholder"
                    type="text"
                    class="search-bar__input" >
            </div>

            <div class="col-2">
                <button class="search-bar__button"/>
            </div>
        </form>
    </div>
</template>

<script>
import formInput from './cleverly/FormInput.vue'

export default {
    name: 'SearchBar',
    components: {
        formInput,
    },
    props: {
        searchAction: {
            type: Function,
        },
        placeholder: {
            type: String,
            default: () => 'Search',
        },
        bound: Boolean,
    },
    data() {
        return {
            baseQuery: '',
        }
    },
    computed: {
        query: {
            get() {
                return this.baseQuery
            },
            set(val) {
                this.baseQuery = val

                this.search()
            },
        },
    },
    methods: {
        search() {
            if (this.searchAction) {
                this.searchAction(this.query)
            }

            this.$emit('search', this.query)
        },
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.search-bar {
    overflow: hidden;

    &__input, &__button {
        width: 100%;
        height: 100%;
        padding: spacing(xs) / 2 spacing(xs);
        border: $grey_light_1 1px solid;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &__input {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    &__button {
        @include background-image('', 'search_logo.svg');
        background-position: center center;

        background-color: $grey_light_2;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
    }
}

</style>
